<template>
  <div>
    <div v-if="!taxesList">
      <feather-icon
        v-b-tooltip.hover.lefttop.v-primary
        title="Editar imposto"
        icon="EditIcon"
        size="15"
        @click="$emit('openModal')"
      />
      <feather-icon
        v-b-tooltip.hover.lefttop.v-primary
        title="Excluir imposto"
        icon="DeleteIcon"
        size="15"
        class="ml-1"
        @click="$emit('openModaDelete')"
      />
    </div>
    <div v-if="taxesList">
      <BButton small name="reative-taxe" @click="activeTaxes()">
        Reativar
      </BButton>
    </div>
  </div>
</template>

<script>
import { VBTooltip, BButton, ToastificationContent } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
export default {
  components: { BButton },
  name: 'actions-taxes',
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    taxesList: { type: Boolean, default: true },
    item: { type: Object, default: () => {} },
  },
  methods: {
    // activeTaxes
    activeTaxes() {
      this.$store
        .dispatch('activeTaxes', { ...this.item, deleted_at: null })
        .then(() => {
          this.$emit('handleUpdateList');
        })
        .catch(() => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'erro ao reativar Imposto',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },
  },
};
</script>
