<template>
  <b-card title="">
    <b-row class="justify-content-start d-flex">
      <b-tabs content-class="mt-3">
        <b-tab
          title="Ativos"
          @click="(taxesList = false), getAllTaxes()"
          active
        >
        </b-tab>
        <b-tab title="Inativos" @click="(taxesList = true), getAllTaxes()">
        </b-tab>
      </b-tabs>
    </b-row>

    <b-row class="justify-content-between d-flex">
      <b-col>
        <b-row class="ml-2">
          <b-form-group>
            <b-form-input
              type="text"
              v-model="search"
              placeholder="Buscar imposto"
            ></b-form-input>
          </b-form-group>
        </b-row>
      </b-col>

      <b-col>
        <b-button class="mb-2 mr-2" variant="primary" @click="openModalDefault">
          Novo imposto +
        </b-button>
      </b-col>
    </b-row>

    <b-table
      responsive="sm"
      small
      :items="filter()"
      :fields="fields"
      class="p-1 w-100"
    >
      <template v-slot:cell(actions)="{ item }">
        <ActionsTaxes
          @openModal="openEditModal(item)"
          @openModaDelete="openDeleteModal(item)"
          @openReativeModal="openReativeModal(item)"
          @handleUpdateList="handleUpdateList()"
          :taxesList="taxesList"
          :item="item"
        />
      </template>

      <template v-slot:cell(tax_percentage)="{ item }">
        {{ item.tax_percentage + '%' }}
      </template>
    </b-table>
    <ModalTaxes
      :modalTaxes="modalTaxes"
      :modalTaxesForm="modalTaxesForm"
      :editable="editable"
      :itemUpdate="itemUpdate"
      @renderList="getAllTaxes"
      @closeModal="closeModal()"
      @handleUpdateList="handleUpdateList()"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BTabs,
  BTab,
  BRow,
  BButton,
  BTable,
  BFormGroup,
  BFormInput,
  BCol,
  // BIconTrash,
} from 'bootstrap-vue';
import { VBTooltip } from 'bootstrap-vue';
import ActionsTaxes from './components/ActionsTaxes.vue';
import ModalTaxes from './components/ModalTaxes.vue';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BTabs,
    BTab,
    BTable,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    // BIconTrash,
    ActionsTaxes,

    ModalTaxes,
  },
  data: () => ({
    modalTaxes: false,
    modalTaxesForm: false,
    modalReative: false,
    editable: false,
    itemUpdate: null,
    fields: [
      {
        key: 'actions',
        label: 'Ações',
        sortable: false,
      },
      {
        key: 'tax_name',
        label: 'imposto',
        sortable: true,
      },
      {
        key: 'tax_percentage',
        label: 'Alíquota do imposto',
        sortable: true,
      },
    ],
    items: [],
    taxesList: false,
    search: '',
  }),

  created() {
    this.getAllTaxes();
  },

  computed: {
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    },
    taxesFilteres() {
      let values = [];
      values = this.items.filter((item) => {
        return item.title.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
      return values;
      // return this.items
    },
  },

  methods: {
    getAllTaxes() {
      this.items = [];
      if (!this.taxesList) {
        this.$store
          .dispatch('getAllTaxes', {
            inactive: false,
            workspace_id: this.$store.getters.currentWorkspace.id,
          })
          .then((resp) => {
            if (resp) {
              this.items = resp;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.getInactivesTaxes();
      }
    },
    getInactivesTaxes() {
      this.$store
        .dispatch('getInactive', {
          inactive: true,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          if (resp) {
            this.items = resp;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    openEditModal(item) {
      this.modalTaxes = true;
      this.editable = true;
      this.modalOpenDelete = false;
      this.modalTaxesForm = true;
      this.itemUpdate = item.id;
    },
    openDeleteModal(item) {
      this.modalTaxes = true;
      this.modalTaxesForm = false;
      this.modalOpenDelete = true;
      this.itemUpdate = item.id;
    },
    openReativeModal(item) {
      this.modalReative = true;
      this.itemReative = item.id;
    },
    openModalDefault() {
      this.modalTaxes = true;
      this.modalTaxesForm = true;
      this.modalOpenDelete = false;
      this.editable = false;
      // this.itemUpdate = item.id
    },
    filter() {
      if (this.search.trim() === '') {
        // If the search input is empty, return all items
        return this.items;
      } else {
        // If there's a search term, filter the items
        return this.items.filter((item) => {
          return (
            item.tax_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      }
    },

    cleanFilter() {
      this.search = '';
    },
    closeModal() {
      this.modalTaxes = false;
      this.editable = false;
      this.modalTaxesForm = false;
      this.modalReative = false;
      this.editable = false;
    },
    handleUpdateList() {
      this.getAllTaxes();
    },
  },
};
</script>
