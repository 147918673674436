<template>
  <b-modal
    v-model="modalTaxes"
    :title="
      editable
        ? 'Editar imposto'
        : modalTaxesForm
        ? 'Cadastrar imposto'
        : 'Excluir imposto'
    "
    class="text-center text-primary d-flex align-items-center"
    no-close-on-backdrop
    hide-footer
    hide-header-close
  >
    <validation-observer ref="ModalTaxes">
      <b-form v-if="modalTaxesForm">
        <b-row
          class="my-1 m-2 text-left mt-4 d-flex justify-content-center flex-column"
        >
          <!-- Tax -->
          <b-form-group class="mb-2">
            <label for="title" class="font-weight-bold text-primary"
              >Imposto :</label
            >
            <validation-provider
              #default="{ errors }"
              :custom-messages="{ required: 'Este campo é obrigatório' }"
              rules="required"
            >
              <b-form-input
                id="title"
                v-model="modelTaxes.tax_name"
                placeholder="Digite o nome do imposto"
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Tax rate -->
          <b-form-group>
            <label for="value" class="font-weight-bold text-primary"
              >Alíquota do imposto :</label
            >
            <validation-provider
              #default="{ errors }"
              :custom-messages="{ required: 'Este campo é obrigatório' }"
              rules="required"
            >
              <b-input-group append="%">
                <b-form-input
                  id="value"
                  type="number"
                  v-model="modelTaxes.tax_percentage"
                  placeholder="Digite a alíquota do imposto"
                ></b-form-input>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-row>

        <hr class="invoice-spacing mb-3" />

        <b-row class="justify-content-between d-flex p-2">
          <!-- back -->
          <b-button
            :disabled="loading"
            variant="warning"
            class="mr-2"
            @click="closeModal()"
            >Voltar
          </b-button>

          <!-- inactivate -->
          <b-button
            v-if="editable ? modelTaxes : modalInactivate.disabled"
            :variant="modelTaxes.is_blocked ? 'success' : 'danger'"
            class="d-flex justify-content-center mr-2"
            @click="modalInactivate = true"
            :disabled="modelTaxes.tax_name.length < 1 || loading"
          >
            <span
              v-if="!loading"
              id="modal-inactivate"
              @click="
                modelTaxes.is_blocked ? updateTaxes : (modalInactivate = true)
              "
              >{{ modelTaxes.is_blocked ? 'Reativar' : 'Inativar' }}</span
            >
            <b-spinner v-if="loading"></b-spinner>
          </b-button>

          <!-- updateTax -->
          <div class="d-flex justify-content-between">
            <b-button
              v-if="editable && !modelTaxes.is_blocked"
              variant="primary"
              @click="updateTaxes"
              :disabled="modelTaxes.tax_name.length < 1 || loading"
            >
              <span v-if="!loading"> Salvar Alterações </span>
              <b-spinner class="spinner" v-if="loading"></b-spinner>
            </b-button>

            <!-- saveTax -->
            <b-button
              v-if="!editable"
              variant="primary"
              @click="saveTaxes"
              :disabled="modelTaxes.tax_name.length < 1 || loading"
            >
              <span v-if="!loading">Salvar</span>
              <b-spinner v-if="loading"></b-spinner>
            </b-button>
          </div>

          <!-- modalInativate -->
          <b-modal
            title="Inativar imposto"
            no-close-on-backdrop
            hide-footer
            hide-header-close
            v-model="modalInactivate"
          >
            <b-row class="mt-1 justify-content-center d-flex text-center">
              <p>
                Deseja realmente
                <strong>{{
                  modelTaxes.is_blocked ? 'reativar' : 'inativar'
                }}</strong>
                este imposto <strong>{{ modelTaxes.tax_name }}</strong
                >?
              </p>
            </b-row>
            <hr class="invoice-spacing mb-3" />
            <b-row class="justify-content-between d-flex mt-3 p-1">
              <b-button variant="warning" @click="modalInactivate = false">
                Cancelar
              </b-button>
              <b-button
                variant="primary"
                @click="modelTaxes.is_blocked ? activeTaxes() : removeTaxes()"
              >
                Confirmar
              </b-button>
            </b-row>
          </b-modal>
        </b-row>
      </b-form>

      <!-- modalOpenDelete -->
      <div v-else modalOpenDelete>
        <div>
          <b-row class="mt-1 justify-content-center d-flex text-center">
            <p>
              Deseja realmente
              <strong>{{ modelTaxes && 'Excluir este imposto ' }}</strong>
              <strong>{{ modelTaxes.tax_name }}</strong
              >?
            </p>
          </b-row>
          <hr class="invoice-spacing mb-3" />
          <b-row class="justify-content-between d-flex mt-3 p-1">
            <b-button variant="warning" @click="$emit('closeModal')">
              Cancelar
            </b-button>
            <b-button variant="primary" @click="removeTaxes()">
              Confirmar
            </b-button>
          </b-row>
        </div>
      </div>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BRow,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BButton,
  BSpinner,
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
/*
  Quando uma instância Vue é criada, ela adiciona todas as propriedades encontradas no objeto data ao sistema de reatividade do Vue. Quando os valores de qualquer destas propriedades muda, a camada visual “reage”, atualizando-se para condizer com os novos valores. A única exceção é ao usar Object.
*/
export default {
  name: 'new-taxes',
  props: {
    editable: {
      Type: Boolean,
      default: false,
    },
    modalTaxes: {
      Type: Boolean,
      default: false,
    },
    modalTaxesForm: {
      Type: Boolean,
      default: false,
    },
    ModalOpenDeleteTaxes: {
      Type: Boolean,
      default: false,
    },
    itemUpdate: {
      type: Object && String,
      default: null,
    },
    tax_id: {
      Type: String,
      default: '',
    },
  },

  components: {
    BModal,
    BForm,
    BRow,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },

  data() {
    return {
      modelTaxes: {
        tax_name: '',
        tax_percentage: '',
        id: '',
        is_blocked: false,
        workspace_id: null,
      },
      modelTaxesDefault: {
        title: '',
        value: '',
        Workspace_id: null,
      },
      modalInactivate: false,
      loading: false,
      required,
    };
  },
  watch: {
    editable(itemUpdate) {
      if (itemUpdate == true) {
        this.getTax();
      }
    },
  },

  computed: {
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    },
  },
  before() {
    if (this.editable) {
      this.getTax();
    }
  },

  methods: {
    modalOpenDelete() {
      this.ModalOpenDeleteTaxes = true;
    },

    closeModal() {
      this.modelTaxes = this.modelTaxesDefault;
      this.$emit('closeModal');
    },

    // saveTaxes
    saveTaxes() {
      this.$refs.ModalTaxes.validate().then((success) => {
        if (success) {
          this.modelTaxes.workspace_id =
            this.$store.getters.currentWorkspace.id;
          this.loading = true;

          this.$store
            .dispatch('saveTaxes', this.modelTaxes)
            .then((resp) => {
              this.loading = false;
              if (resp) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Imposto cadastrado com sucesso',
                    icon: 'SuccessIcon',
                    variant: 'success',
                  },
                });
                this.$emit('handleUpdateList');
                this.$emit('closeModal');
              }
            })

            .catch(() => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Erro ao cadastrar imposto',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              });
            });
        }
      });
    },

    // getTaxes
    getTax() {
      this.loading = true;

      let tax_id = this.itemUpdate;

      this.$store
        .dispatch('getTax', {
          id: tax_id,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          this.loading = false;
          this.modelTaxes = resp;
        })
        .catch(() => {
          this.loading = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao buscar imposto',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },

    // updateTaxes
    updateTaxes() {
      this.$refs.ModalTaxes.validate().then((success) => {
        if (success) {
          this.loading = true;
          this.$store
            .dispatch('updateTaxes', this.modelTaxes)
            .then((resp) => {
              this.loading = false;
              if (resp) {
                this.$emit('renderList');
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Imposto atualizado com sucesso',
                    icon: 'SuccessIcon',
                    variant: 'success',
                  },
                });
                this.$emit('closeModal');
              }
            })
            .catch(() => {
              this.loading = false;

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'erro ao atualizar o imposto',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              });
            });
        }
      });
    },

    // inactivateTaxes
    inactivateTaxes() {
      this.loading = true;
      this.$store
        .dispatch('inactivateTaxes', this.modalTaxes)
        .then((resp) => {
          this.loading = false;
          if (resp) {
            this.modalInactivate = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Imposto inativada com sucesso',
                icon: 'SuccessIcon',
                variant: 'success',
              },
            });
            this.$router.push({ name: 'taxes' });
          }
        })
        .catch(() => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'erro ao inativa imposto',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },

    // activeTaxes
    activeTaxes() {
      this.$refs.ModalTaxes.validate().then((success) => {
        if (success) {
          this.loading = true;
          this.$store
            .dispatch('activeTaxes', this.modalTaxes)
            .then((resp) => {
              this.loading = false;
              if (resp) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Imposto reativado com sucesso',
                    icon: 'SuccessIcon',
                    variant: 'success',
                  },
                });
                this.$router.push({ name: 'taxes' });
              }
            })
            .catch(() => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'erro ao reativar Imposto',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              });
            });
        }
      });
    },

    // removeTaxes
    removeTaxes() {
      this.$refs.ModalTaxes.validate().then((success) => {
        if (success) {
          this.loading = true;
          this.$store
            .dispatch('removeTaxes', { id: this.itemUpdate })
            .then((resp) => {
              this.loading = false;
              if (resp) {
                this.$emit('renderList');
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Imposto removido com sucesso',
                    icon: 'SuccessIcon',
                    variant: 'success',
                  },
                });
                this.$emit('closeModal');
              }
            })
            .catch(() => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Erro ao deletar imposto',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              });
            });
          this.$emit('closeModal');
          this.modelTaxes = this.modelTaxesDefault;
        }
      });
    },
  },
};
</script>
